/* Provide sufficient contrast against white background */

figure#main-img {
    width: 100%;
    height: 80vh;
    display: block;
    overflow: hidden;
    text-align: center;
    padding-top: 20px;
}

    figure#main-img img {
        height: 100vh;
        object-fit: contain;

    }

    .map-overlay {
        font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
        width: 200px;        
        padding: 10px;
        }
         
        .map-overlay .map-overlay-inner {
        background-color: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        padding: 10px;
        margin-bottom: 10px;
        }
         
        .map-overlay-inner fieldset {
        border: none;
        padding: 0;
        margin: 0 0 10px;
        }
         
        .map-overlay-inner fieldset:last-child {
        margin: 0;
        }
         
        .map-overlay-inner select {
        width: 100%;
        }
         
        .map-overlay-inner label {
        display: block;
        font-weight: bold;
        margin: 0 0 5px;
        }
         
        .map-overlay-inner button {
        display: inline-block;
        width: 36px;
        height: 20px;
        border: none;
        cursor: pointer;
        }
         
        .map-overlay-inner button:focus {
        outline: none;
        }
         
        .map-overlay-inner button:hover {
        box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
        }


.pulse {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}