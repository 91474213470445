
.map-container {
    width: 100%;
    height: 80vh;
}

.sidebarStyle {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
}

.mytooltip > .tooltip-inner {
    max-width: 100%;
}

.textarea {
    width: 100%
}
.gridColumn {
    position: absolute;
}